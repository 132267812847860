.container-img{
    opacity: 0;
    transform: translateY(-100px);
    transition: all 0.5s ease-in;
}


.container-img.visible {
    opacity: 1;
    transform: translateY(0);
  }


  .tagline-visible{
    transform: translateX(-200px);
    animation: stepin 2s forwards;
  }

  @keyframes stepin {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
        transform: translateX(0);
    }
  }


  .home-about-img-a.visible{
    animation: joinSlideA 2s forwards;
  }

  @keyframes joinSlideA{
    0%{
        top: -50px;
        left: -50px;
        box-shadow: 6px 6px 12px #152d56;
    }
    100%{
        top: 0;
        left: 0;
    }
  }


  .home-about-img-b.visible{
    animation: joinSlideB 2s forwards;
  }

  @keyframes joinSlideB{
    0%{
        bottom: -50px;
        right: -50px;
        box-shadow: 6px 6px 12px #152d56;
    }
    100%{
        bottom: 0;
        right: 0;
    }
  }
