body{
  margin: 0;
  padding: 0;
  background: #ffffff !important;
}

@import url('https://fonts.googleapis.com/css2?family=League+Gothic&display=swap');

.Logo-text{
  font-family: "League Gothic", sans-serif;
  font-size: 22px;
}