/* Start of Header Logo */

.logo-container{
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo-icon{
  max-height: 80px;
}
.logo-text{
  max-height: 70px;
}

/* End of Header Logo */




/* Hide all submenus by default */
.submenu {
  display: none;
  position: absolute;
  left: 100%; /* Make submenus appear to the right */
  top: 0;
  background: #ffffff;
  color: #152d56;
  border: 1px solid #e6e6e6;
  padding: 0;
  border-radius: 4px;
  z-index: 1005;
}


/* Positioning for the list */
/* .service-menu:hover > .submenu {
  display: block;
} */

.service-menu > .submenu {
  top: 45px;
  left: 0;
}


/* Show the submenu when the parent is hovered */
.has-submenu:hover > .submenu {
  display: block;
}


/* Styling to make the list items more presentable */
.header-menu-list li {
  list-style-type: none;
  position: relative;
  min-width: max-content;
  max-width: 100%;
  font-weight: 600;
}

.has-submenu {
  position: relative;
}

.has-submenu > .submenu {
  top: 0;
  left: calc(100%); /* Place the submenu to the right of the parent */
  z-index: 10;
}

.submenu li {
  padding-left: 10px;
  white-space: nowrap;
}


.header-menu-list li:hover {
  background: #152d56;
  color: #ffffff;
  border-radius: 4px;
}





.accordion {
  max-width: 600px; /* Adjust as needed */
  margin: auto;
}

.accordion-item {
  border: 1px solid #ccc;
  margin: 5px 0;
}

.accordion-header {
  padding: 10px;
  background-color: #f0f0f0;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.accordion-content {
  padding: 10px;
  background-color: #fafafa;
  border-top: 1px solid #ccc;
}






/* Start of Custom Accordion */

/* .accordion-parent{
  margin: 12px 0;
} */

.accordion-items{
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  padding: 6px 24px;
  margin: 4px;
  box-sizing: border-box;
  /* border: 1px solid #658aca; */
  /* color: #152d56; */

}

.accordion-items-parent{
  background: #f0f9ff;
  color: #072e4a;
}

.accordion-items-child{
  background: #b9e5fe;
  color: #072e4a;
}

.accordion-items-grandchild{
  background: #0168a3;
  color: #f0f9ff;
}

.accordion-items-grandchild-child{
  background: #f0f0f0;
  color: #072e4a;
}

/* End of Custom Accordion */


.header-translation h5, .header-translation button{
  font-size: 12px !important;
}



.landing-text-secondary{
  color: #36bcfa;
}
.landing-desc{
  text-align: justify;
}
.landing-btn{
  background: #36bcfa;
  border: 0px;
  font-weight: 450;
}
.landing-btn:hover{
  transition: all 1s;
  background: #36bcfa;
  filter: drop-shadow(0 0 4px #152d56) !important;
}
.landing-icons{
  font-size: 38px;
  border: 2px solid #36bcfa;
  padding: 0 8px;
  background: #edf8ff;
}
.landing-icons:hover{
  transition: all 1s;
  background: #36bcfa;
  filter: drop-shadow(0 0 4px #152d56) !important;
  transform: scale(110%);
  transition: 950 ease-in-out;
  cursor: pointer;
}
.container-img{
  box-sizing: border-box !important;
  width: 100%;
  margin-bottom: -120px;
  /* filter: drop-shadow(12px 12px 12px #36BCFA) !important; */
  filter: drop-shadow(4px 4px 8px #4b547e) !important;
}

.landing-footer-container{
  padding: 10px;
  background: #454D21;
}

.landing-footer-icon{
  background: #e1e6b0;
  color: #301f0d;
  padding: 3px 8px;
  border-radius: 50%;
  margin-right: 10px;
}

.landing-footer-container b{
  color: #e1e6b0;
}




.home-about-container{
  position: relative;
  background: linear-gradient(90deg, #152d56 0%, #349bfd 100%);
  border-radius: 4px 30% 4px 30% !important;
  aspect-ratio: 1/1;
  box-sizing: border-box;
  /* overflow: hidden; */
}
.home-about-container-inner{
  border-radius: 30%;
  box-shadow: 0 0 30px #87fbff;
}
.home-about-img{
  width: 60%;
  height: 65%;
  object-fit: cover;
  object-position: top;
  position: absolute;
  border-radius: 4px;
}







.service-card{
  max-width: 350px;
  height: auto;
  border-radius: 26px;
  box-shadow: 0 0 8px #99a3aa;
  position: relative;
}
.service-card:hover{
  transition: 800 ease-in-out;
  box-shadow: 0 0 12px #99a3aa;
}
.service-card:hover .service-btn{
  background-color: #349bfd;
  color: #020202;
  transition: 900 ease-in-out;
  transform: scale(120%);
}
.service-card:hover .service-card-img-layer3-inner{

  -webkit-filter: brightness(90%);
  filter: brightness(90%);

}


.service-btn-container{
  position: absolute;
  z-index: 1001;
  bottom: 0;
  right: 0;

  margin: 0;
  border-radius: 40px 0 40px 0;
  width: 101px;
  height: 96px;
}
.service-btn{
  color: #020202;
  font-size: 40px;
  border: 2.5px solid #349bfd;
  padding: 0 12px;
  border-radius: 50%;
  background-color: #e7e6e6;
}
.service-card-title{
  display: -webkit-box;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}
.service-card-desc{
  display: -webkit-box;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}
.service-card-img-layer-container{
  position: relative;
  width: 100%;
  height: 250px;
  top: -20px;
  margin-top: 20px;
}
.service-card-img-layer1{
  width: 78%;
  background: #7cd2fd;
  height: 250px;
  border-radius: 12px;
  position: absolute;
  z-index: 998;
  top: 0;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  box-sizing: border-box;
}
.service-card-img-layer2{
  width: 88%;
  background: #eeeeee;
  height: 240px;
  border-radius: 12px;
  position: absolute;
  z-index: 999;
  top: 10px;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  box-sizing: border-box;
}
.service-card-img-layer3{
  width: 100%;
  height: 250px;
  border: 1px solid rgb(207, 207, 207);
  height: 250px;
  border-radius: 12px 12px 26px 26px;
  position: absolute;
  z-index: 1000;
  top: 20px;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  box-sizing: border-box;
}
.service-card-img-layer3-inner{
  width: 100%;
  height: 100%;
  mix-blend-mode: screen;
  object-fit: cover;

  -webkit-filter: brightness(80%);
  filter: brightness(80%);

  /* opacity: 0; */
}
.service-card-img-layer4{
  width: 100%;
  height: 250px;
  border: 1px solid rgb(207, 207, 207);
  height: 250px;
  border-radius: 12px 12px 26px 26px;
  position: absolute;
  z-index: 1000;
  top: 20px;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  box-sizing: border-box;
  background-size: cover !important;
  filter: brightness(80%);
}



.testimonial-card-container{
}
.testimonial-img-container{
  position: relative;
  width: 100%;
  height: calc(250px - 120px);
}
.testimonial-img{
  position: absolute;
  top: -120px;
  height: 250px;
  border-radius: 50% 50% 50% 0;
  filter: drop-shadow(-10px 10px 0 #0ca4eb);
  margin-left: 10px;
  object-fit: contain;
}
.testimonial-card{
  width: 350px;
  position: relative;
  border: 2px solid #8d8b8a;
  border-radius: 50% 50% 26px 26px;
  margin: 130px 10px 10px 10px;
}
.testimonial-card p{
  text-align: justify;
}




/* Start of Testimonial Sliders */
.slider-container {
  width: 100%;
  height: fit-content;
  overflow: hidden;
}

.sliders {
  display: flex;
  animation: scroll linear infinite;
  animation-play-state: running;
  animation-duration: 80s;
}


.testimonials-img {
  height: 70px;
  border-radius: 50%;
  object-fit: contain;
  border: 1px solid #072e4a;
}

.testimonials-card {
  flex: 0 0 300px; 
  box-shadow: 0 0 8px #99a3aa;
  border-radius: 26px;
  margin: 10px;
  padding: 10px;
  font-size: 18px;
  /* max-height: 450px; */
}

.testimonials-card p {
  text-align: justify;
}
.quote-icon-end{
  display: inline-block;
  transform: rotateY(150deg);
}
.gallery-img{
  border: 3px solid rgb(170, 178, 184);
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-320px*20));
  }
}

/* End of Testimonial Sliders */









.service-img{
  height: 400px;
  aspect-ratio: 1/1;
  object-fit: cover;
  max-width: 100%;
}


.about-history-container{
  background: #F8F8F8;
}

.about-sections{
  margin: 50px 5px;
}



.count-card{
  color: #065886;
  /* background: #f0f9ff; */
  border-radius: 26px;
  box-shadow: 0 0 12px #d2d8db;
}


.why-choose-list li::marker{
  content: url(../assets/right-arrow\ \(1\).png)' ';
}

.about-offers-card{
  border-radius: 26px;
  box-shadow: 2px 2px 24px #dad8d8;
  background: #f9fbff;
}

.career-img{
  filter: drop-shadow(0 0 4px #44527e);
}

.about-offers-card-icon{
  background: white !important;
  box-shadow: 2px 2px 24px #c2c2c2 !important;
  padding: 20px;
  border-radius: 26px;
}

.contact-card-container{
  border-radius: 26px;
  box-shadow: 0 0 24px #c2c2c2;
}

.fs-normal{
  font-size: 18px;
  line-height: 30px;
}

.job-card{
  border-radius: 8px;
  box-shadow: 0 0 12px #c2c2c2;
}




.modal-container{
  background-color: rgba(0, 0, 0, 0.85);
  height: 90vh;
}

.gray-font{
  color: #d3e2eb;
}


.navbar{
  position: relative;
}

.h-trend-menulist{
  list-style-type: none;
  font-weight: 600;
}

.h-trend-menulist-item{
  position: relative;
}


.h-trend-menulist-categories{
  display: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 95px;
  left: 0;
  z-index: 1003;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(1px);
}
.h-trend-menulist-categories>div{
  margin-top: -45px;
  width: fit-content;
  height: fit-content;
  border-radius: 26px;
  backdrop-filter: brightness(90%);
  backdrop-filter: blur(30px) !important;
  box-shadow: 2px 2px 4px #abb3b8;
}

.h-trend-menulist-ul > li{
  list-style-image: url(../assets/tick-inside-circle.png);
  font-size: 14.5px;
  line-height: 24px;
  color: #052130;
}
.h-trend-menulist-ul ul {
  list-style-image: none;
  font-size: inherit; /* Use if you want to keep the font size same as the parent */
  line-height: inherit; /* Keeps line height same as parent */
  color: inherit; /* Keeps text color same as parent */
}
.h-trend-sub-menulist-ul{
  list-style-type: circle;
}

.sub-menu-items{
  text-wrap: wrap !important;
  box-sizing: border-box !important;
}
.header-menu-items:hover{
  color: #1C4D98;
}
.menu-items:hover{
  text-decoration: underline;
  text-underline-offset: 2px !important;
  transition: 200 ease-in-out;
}
.sub-menu-items:hover{
  display: inline-block;
  transform: scale(102%);
  transition: 100 transform ease-in-out;
}
.child-submenu-items:hover{
  color: #2f7ca0;
  transition: 100 ease-in-out;
}

.h-trend-menulist-item:hover > .h-trend-menulist-categories{
  display: flex;
}

.app-container{
  position: relative;
}
.whatsapp-container{
  position: fixed;
  top: 90vh;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(0 0 4px #0d1733);
  aspect-ratio: 1/1;
  width: 70px;
  cursor: pointer;
  animation: wtblink 3s infinite;
  transition: all 800 ease-in-out;
  box-sizing: border-box !important;
}

@keyframes wtblink {
  50%{
    opacity: 0;
  }
}



#Intern-img.visible{
  animation: blink 1s forwards;
}

.project-card.visible{
  animation: blink 1s forwards;
}

@keyframes blink{
  0%{
    transform: scale(50%);
  }
  100%{
    transform: scale(100%);
  }
}


.carousel-btn{
  filter: drop-shadow(0 0 12px #2b3038);
  padding: 4px;
}

.carousel-img{
  object-fit: contain;
  width: 100%;
  height: 100vh;
}


.projects-galler-card{
  position: relative;
  height: 245px;
}
.projects-galler-card h3{
  margin-top: -10px;
}

.layer-a, .layer-b, .layer-c{
  position: absolute;
  width: 90%;
  height: calc(90% * 0.75);
  border-radius: 12px;
}

.layer-a{
  z-index: 1000;
  background-color: #7CD2FD;
  top: 0;
  left: 0;
}
.layer-b{
  z-index: 1001;
  background-color: #EEEEEE;
  top: 10px;
  left: 10px;
}
.layer-c{
  z-index: 1002;
  top: 20px;
  left: 20px;
}
.layer-c-img{
  border-radius: 12px;
  object-fit: cover;
}

.layer-c-outer{
  position: relative;
  border-radius: 12px;
}
.layer-c-inner{
  padding-top: 20px;
  position: absolute;
  bottom: 0;
  border-radius: 0 0 12px 12px;
  background: linear-gradient(-180deg, transparent 0%, #000000 100%);
}
.layer-c-inner h3{
  color: #77d4ff;
}
.layer-c-inner p{
  font-weight: 600;
  display: none;
}

.projects-galler-card:hover .layer-c-inner p{
  display: block;
}

.projects-galler-card:hover .layer-c-img{
  filter: brightness(60%);
}



.footer-logo{
  filter: drop-shadow(0 0 2px #ffffff);
  background: #ffffff;
}




.lists-container{

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: top;

}

.list-container{
  width: max-content;
  height: max-content;
  margin: 20px;
}

.list-container > h3{
  font-weight: 600;
  color: #1C4D98;
}

.list-container > ul{
  font-size: 18px;
}


/* Start of Team CSS */

/* Team Member Card */
.team-card {
  text-align: center;
  padding: 20px;
  border-radius: 10px;
  background: #fff;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Circular Image Container */
.team-img-container {
  width: 150px;
  height: 150px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 123, 255, 0.5);
  transition: all 0.3s ease-in-out;
}

.team-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.4s ease-in-out;
}

/* Name and Role */
.team-name {
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 15px;
  transition: color 0.3s ease-in-out;
}

.team-role {
  font-size: 1rem;
  color: #666;
  font-weight: 500;
}

/* Hover Effects */
.team-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 123, 255, 0.2);
}

.team-card:hover .team-img-container {
  box-shadow: 0 0 20px rgba(0, 123, 255, 0.7);
}

.team-card:hover .team-img {
  transform: scale(1.1);
}

.team-card:hover .team-role {
  color: rgb(14, 53, 95)
}

/* Fade-in Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* End of Team CSS */


@media (max-width: 285px){
  .logo-text{
    display: none;
  }
}

@media (max-width: 347px) {
  .service-card-img-layer3{
    -webkit-mask-image: url('../assets/SAN\ Planners\ -\ Service\ card\ mask\ 300.png') !important;
    mask-image: url('../assets/SAN\ Planners\ -\ Service\ card\ mask\ 300.png') !important;
    mask-repeat: no-repeat;
    mask-size: fill !important;
  }
}


@media (min-width: 347px) {
  .service-card-img-layer3{
    -webkit-mask-image: url('../assets/SAN\ Planners\ -\ Service\ card\ mask\ 350.png') !important;
    mask-image: url('../assets/SAN\ Planners\ -\ Service\ card\ mask\ 350.png') !important;
    mask-repeat: no-repeat;
    mask-size: fill !important;
  }
}

@media (min-width: 347px) and (max-with: 394px) {
  .service-btn-container{
    position: absolute;
    z-index: 1001;
    bottom: 0;
    right: 0;
  
    margin: 0;
    border-radius: 35px 0 35px 0;
    width: 101px;
    height: 96px;
  }
}

@media (max-width: 400px){
  .home-about-container{
    width: 85%;
  }
  .projects-galler-card{
    width: 95%;
  }
}

@media (min-width: 400px){
  .home-about-container{
    width: 350px;
  }
  .projects-galler-card{
    width: 320px;
  }
}

@media (max-width: 600px){
  .lists-container-block{
    display: flex;
    flex-wrap: wrap;
    justify-content: center !important;
  }
}

@media (min-width: 600px){
  .lists-container{

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: top;
  
  }
}

@media (max-width: 1100px){
  .header-translation{
    display: none !important;
  }
  .h-trend-menulist{
    display: none !important;
  }

  .header-ham{
    display: inline !important;
  }
  .header-menu-list{
    display: none !important;
  }
  .header-side-blocks{
    width: 40%;
  }
  .landing-title{
    font-size: 10vw;
    font-weight: 900;
  }
  .landing-icons{
    padding: 4.5px 8px;
  }
  .about-history-desc{
    font-size: 32px;
  }

  .job-card{
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    align-items: center;
    gap: 5px;
  }
  
}

@media (max-width: 1258px){
  .h-trend-menulist{
    display: none !important;
  }
  .header-cta{
    display: none !important;
  }
  .landing-footer-container{
    padding-top: 100px !important;
  }
  .contact-card{
    border-radius: 26px 0 0 26px;
    padding: 18px;
  }
  .contact-form-card{
    border-radius: 0 0 26px 26px;
    padding: 18px;
  }
}

@media (min-width: 1259px){
  .header-translation{
    display: flex !important;
    align-items: center;
    gap: 10px;
  }
  .h-trend-menulist{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header-cta{
    display: inline !important;
  }
  .header-ham{
    display: none !important;
  }
  .header-menu-list{
    display: flex !important;
  }
  .header-side-blocks{
    width: 20%;
  }
  .landing-title{
    font-size: 68px;
    font-weight: 900;
  }
  .landing-desc{
    line-height: 38px;
  }
  .about-history-desc{
    font-size: 5vw;
  }
  .contact-card{
    border-radius: 26px 0 0 26px;
    padding: 38px;
  }
  .contact-form-card{
    border-radius: 0 26px 26px 0;
    padding: 38px;
  }
  .job-card{
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 5px;
  }

}



@media (max-width: 992px){
  .footer-logo-container{
  }
  .footer-content-container{
    padding-left: 50px !important;
    padding: 10px;
  }
  .contact-card{
    border-radius: 26px 26px 0 0;
    padding: 18px;
  }
  .contact-form-card{
    border-radius: 0 0 26px 26px;
    padding: 18px;
  }
}

@media (min-width: 992px){
  .footer-logo-container{
    transform: skew(-10deg);
    margin-left: 40px;
  }
  .footer-content-container{
    padding-left: 50px !important;
    padding: 10px;
  }
  .footer-logo{
    transform: skew(10deg);
  }
  
}


